import React from 'react';
import './Admissions2k23card.css';

interface Admissions2k23cardProps {
  name: string;
  seats: number;
  trade: string;
  rank?: number; // Optional rank property
  new?: boolean; // Optional new property
}

const Admissions2k23card: React.FC<Admissions2k23cardProps> = (props) => {
  return (
    <div className='admissionscard'>
      <h3>{props.name}</h3>
      <div className="badgeholder">
        <p className='course-admission2k23'>{props.trade}</p>
        {/* {props.rank && <p className='course-admission2k23'> Last Rank: {props.rank}</p>}  */}
        {/* Conditional rendering if rank is provided */}
        <p className='seats-admission2k23'>{props.seats} Seats</p>
        {/* {props.name === "Electronics(VLSI design and technology) (Starting this year)" &&     <a href='https://www.mec.ac.in/model_media/documents/MEC_BTech_VLSI.pdf' className='Flyer'>Flyer</a>} */}
        {props.new === true && <p id='newbadge'>NEW</p>}
      </div>
    </div>
  );
}

export default Admissions2k23card;
